@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom colors
$primary: #3399ff !default;
$secondary: #006699 !default;
$tertiary: #ff3b6e !default;
$white: #F3EBE8 !default;
$black: #131237 !default;

// Custom Images
$header-image: "../img/header.png";
$contact-image: "../img/contact.png";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";
