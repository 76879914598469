// Styling for the portfolio section
#portfolio {
  .portfolio-item {
    right: 0;
    margin: 0 0 15px;
    .portfolio-link {
      position: relative;
      display: block;
      max-width: 400px;
      margin: 0 auto;
      cursor: pointer;
      .portfolio-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        opacity: 0;
        background: fade-out($tertiary, .1);
        &:hover {
          opacity: 1;
        }
        .portfolio-hover-content {
          font-size: 20px;
          position: absolute;
          top: 50%;
          width: 100%;
          height: 20px;
          margin-top: -12px;
          text-align: center;
          color: white;
          i {
            margin-top: -12px;
          }
          h3,
          h4 {
            margin: 0;
          }
        }
      }
    }
    .portfolio-caption {
      max-width: 400px;
      margin: 0 auto;
      padding: 25px;
      text-align: center;
      background-color: $white;
      h4 {
        margin: 0;
        text-transform: none;
      }
      p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
        @include serif-font;
      }
    }
  }
  * {
    z-index: 2;
  }
}
@media(min-width:767px) {
  #portfolio {
    .portfolio-item {
      margin: 0 0 30px;
    }
  }
}

.portfolio-modal {
  .modal-dialog {
    margin: 1rem;
    max-width: 100vw;
  }
  .modal-content {
    padding: 100px 0;
    text-align: center;
    h2 {
      font-size: 3em;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 30px;
    }
    p.item-intro {
      font-size: 16px;
      font-style: italic;
      margin: 20px 0 30px;
      @include serif-font;
    }
    ul.list-inline {
      margin-top: 0;
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 30px;
    }
    button {
      cursor: pointer;
    }
  }
  .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    cursor: pointer;
    background-color: transparent;
    &:hover {
      opacity: 0.3;
    }
    .lr {
      /* Safari and Chrome */
      z-index: 1051;
      width: 1px;
      height: 75px;
      margin-left: 35px;
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: $gray-900;
      .rl {
        /* Safari and Chrome */
        z-index: 1052;
        width: 1px;
        height: 75px;
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        background-color: $gray-900;
      }
    }
  }
}
